/**
 * Back to top button
 * - Visible when the user scrolls down
 * - Fixed at the bottom of the viewport
 * - Won't overlap the footer
 * - Scrolls to the top of the page when clicked
 */
export default {
	footer: document.querySelector("footer.footer"),
	backToTopButton: document.querySelector(".backToTop__button") as HTMLElement,
	reducedMotionMediaQuery: window.matchMedia('(prefers-reduced-motion: reduce)'),
	init() {
		this.checkVisibility();

		// Listen on scroll event in order to change the position of the back to top button
		document.addEventListener("scroll", this.handleScroll.bind(this));

		// Listen on click event in order to scroll to the top of the page
		this.backToTopButton.addEventListener("click", this.handleClick.bind(this));
	},
	preferesReducedMotion() {
		return this.reducedMotionMediaQuery?.matches;
	},
	checkVisibility() {
		// If the scroll position is at the top of the page, hide the back to top button
		if (window.scrollY === 0) {
			this.backToTopButton.classList.add("backToTop__button--hidden");
		} else {
			this.backToTopButton.classList.remove("backToTop__button--hidden");
		}
	},
	handleScroll() {
		const footerTop =this.footer?.getBoundingClientRect().top;
		const windowHeight = window.innerHeight;

		this.checkVisibility();

		if (footerTop && footerTop <= windowHeight) {
			this.backToTopButton.classList.remove("backToTop__button--fixed");
		} else {
			this.backToTopButton.classList.add("backToTop__button--fixed");
		}
	},
	handleClick(e: Event) {
		e.preventDefault();

		const behavior = this.preferesReducedMotion() ? "auto" : "smooth";

		window.scrollTo({
			top: 0,
			behavior,
		});
	}
};
