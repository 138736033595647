// Import page elements
import BackToTop from './page-elements/back-to-top-button';
import HeaderHome from './page-elements/header-home';
import { MainNavigation } from './page-elements/main-navigation';

// Import bricks
import BoldText from './bricks/bold-text';
import Gallery from './bricks/gallery';
import VideoLarge from './bricks/video-large';

document.addEventListener('DOMContentLoaded', () => {
	// Initialize page elements
	BackToTop.init();
	HeaderHome.init();
	MainNavigation.init();

	// Initialize bricks
	BoldText.init();
	Gallery.init();
	VideoLarge.init();
});
