/**
 * Homepage header animation
 */
export default {
	container: document.querySelector('.headerHome__bg') as HTMLElement | null,
	flashes: null as NodeListOf<HTMLElement> | null,
	init() {
		this.flashes = this.container?.querySelectorAll('.headerHome__flash') as NodeListOf<HTMLElement>;

		// add mouseover event to container
		this.container?.addEventListener("mousemove", this.checkCollision.bind(this) as EventListener);
		this.container?.addEventListener("mouseleave", this.removeHighlight.bind(this) as EventListener);
	},
	checkCollision(e: MouseEvent) {
		const elements = document.elementsFromPoint(e.clientX, e.clientY) as HTMLElement[];
		const flashPaths = elements.filter(el => el.classList.contains('flash-path'));
		const toBeHighlightedFlashIds = flashPaths.map(el => (el.closest('.headerHome__flash') as HTMLElement).id);

		this.flashes?.forEach(flash => {
			if (toBeHighlightedFlashIds.includes(flash.id)) {
				flash.classList.add(`headerHome__flash--highlighted`);
			} else {
				flash.classList.remove(`headerHome__flash--highlighted`);
			}
		});
	},
	removeHighlight() {
		this.flashes?.forEach(flash => {
			flash.classList.remove(`headerHome__flash--highlighted`);
		});
	}
}
