/**
 * Video Large
 * - clicking the play button opens and plays the video and hides the poster image
 */
export default {
	instances: document.querySelectorAll('.videoLarge') as NodeListOf<HTMLElement>,
	init() {
		if(this.instances.length > 0) {
			this.bindEvents();
		}
	},
	bindEvents() {
		// clicking the play button reveals and plays the video and hides the poster image
		this.instances.forEach((instance) => {
			const playButton = instance.querySelector('.videoLarge__playButton') as HTMLElement;
			const video = instance.querySelector('video') as HTMLVideoElement;
			const poster = instance.querySelector('.videoLarge__poster') as HTMLElement;

			playButton.addEventListener('click', () => {
				video.play();
				playButton.remove();
				poster.classList.add('videoLarge__poster--hidden');
			});
		});
	}
};
