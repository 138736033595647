import { tns } from 'tiny-slider';

/**
 * Gallery
 * - Initializes Tiny Slider for all galleries
 */
export default {
	init() {
		const galleries = Array.from(
			document.getElementsByClassName('gallery')
		);

		galleries.forEach((gallery) => {
			const slider = gallery.querySelector('.gallery__inner');
			const controls = gallery.querySelector('.gallery__controls');
			const nav = gallery.querySelector('.gallery__nav');

			if (!slider || !controls || !nav) {
				return;
			}

			const tinySlider = tns({
				container: slider,
				items: 1,
				slideBy: 'page',
				controlsContainer: controls,
				navContainer: nav,
				navPosition: 'bottom',
			});

			// Lib sets tabindex to -1 for all controls
			// Set tabindex to 0 for all controls for better accessibility
			controls.setAttribute('tabindex', '-1');
			Array.from(controls.children).forEach((control) => {
				control.setAttribute('tabindex', '0');
			});

			const setNavTabIndex = () => {
				Array.from(nav.children).forEach((navElem) => {
					navElem.setAttribute('tabindex', '0');
				});
			};

			setNavTabIndex();

			// Nav elements will receive tabindex -1 on each transition
			// Set tabindex to 0 for all nav elements on transition start
			tinySlider.events.on('transitionStart', setNavTabIndex);
		});
	},
};
