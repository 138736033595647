/**
 * Helper class to handle animations for the bold text
 */
class BoldTextAnimation {
	element: HTMLElement;
	offsetRow1 = 0;
	offsetRow2 = 0;
	scrollHandler: EventListener | null;

	constructor(element: HTMLElement) {
		this.element = element;

		this.calculateOffsets();

		// Store the scroll handler in order to remove it later
		this.scrollHandler = this.transformText.bind(this);
	}

	// Calculate the offset for the text rows to prevent bad readability
	calculateOffsets() {
		const [row1, row2] = Array.from(this.element.children) as HTMLElement[];
		const row1Width = row1.getBoundingClientRect().width;
		const row2Width = row2.getBoundingClientRect().width;
		const text1Width = (row1.firstChild as HTMLElement).getBoundingClientRect().width;
		const text2Width = (row2.firstChild as HTMLElement).getBoundingClientRect().width;

		const navHeight = document.querySelector('.mainNav')?.getBoundingClientRect().height || 0;

		// offset calculation based on text overwidth and nav height
		this.offsetRow1 = (text1Width - row1Width) > 0 ? (text1Width - row1Width) + navHeight * 1.25 : 0;
		this.offsetRow2 = (text2Width - row2Width) > 0 ? (text2Width - row2Width) + navHeight * 1.25 : 0;
	}

	// Remove the observer and event listener and reset the text position to its initial state
	reduceMotion() {
		this.element.querySelectorAll('.boldText__row').forEach((row) => {
			(row as HTMLElement).style.transform = 'none';
		});
		['scroll', 'touchmove'].forEach((event) => {
			document.removeEventListener(event, this.scrollHandler as EventListener);
		});
	}

	handleIntersection() {
		// Call transform method initially in case the text is already visible
		this.transformText();

		// Listen on scroll and touchmove events in order to transform
		['scroll', 'touchmove'].forEach((event) => {
			document.addEventListener(event, this.scrollHandler as EventListener);
		});
	}

	handleNoIntersection() {
		// Stop listening on scroll and touchmove events to prevent unnecessary calculations
		['scroll', 'touchmove'].forEach((event) => {
			document.removeEventListener(event, this.scrollHandler as EventListener);
		});
	}

	transformText() {
		const [row1, row2] = Array.from(this.element.children) as HTMLElement[];
		const textTop = this.element.getBoundingClientRect().top;

		row1.style.transform = `translateX(${this.offsetRow1 + textTop * -0.5}px)`;
		row2.style.transform = `translateX(${-this.offsetRow2 + textTop * 0.5}px)`;
	}
}

/**
 * Bold text
 * - The text is animated when it comes into view
 * - The Animation is based on the scroll position and transforms the text
 * - If the user prefers reduced motion, the animation is disabled
 */
export default {
	intersectionObserver: null as IntersectionObserver | null,
	boldTextElements: document.querySelectorAll('.boldText__rows') as NodeListOf<HTMLElement>,
	boldTexts: null as BoldTextAnimation[] | null,
	reducedMotionMediaQuery: window.matchMedia('(prefers-reduced-motion: reduce)'),
	init() {
		if (!this.boldTextElements) return;

		// Create an IntersectionObserver to check if the text is visible
		this.intersectionObserver = new IntersectionObserver(
			this.handleIntersection.bind(this),
			{
				rootMargin: '10px',
				threshold: 0
			}
		);

		this.initBoldTexts();
		this.bindEvents();
	},
	initBoldTexts() {
		this.boldTexts = Array.from(this.boldTextElements).map((element) => {
			const boldText = new BoldTextAnimation(element as HTMLElement)

			// Only animate the text if the user does not prefer reduced motion
			if (!this.preferesReducedMotion()) {
				this.intersectionObserver?.observe(element);
			}

			return boldText;
		});
	},
	preferesReducedMotion() {
		return this.reducedMotionMediaQuery?.matches;
	},
	handleIntersection(entries: IntersectionObserverEntry[]) {
		entries.forEach((entry) => {
			// Get the bold text element from the entry
			const boldText = this.boldTexts?.find((boldText) => boldText.element === entry.target);

			if (entry.isIntersecting) {
				boldText?.handleIntersection();
			} else {
				boldText?.handleNoIntersection();
			}
		});
	},
	bindEvents() {
		// If the user prefers reduced motion, disable the animation
		this.reducedMotionMediaQuery.addEventListener('change', () => {
			if (this.preferesReducedMotion()) {
				this.boldTexts?.forEach((boldText) => {
					this.intersectionObserver?.unobserve(boldText.element);
					boldText.reduceMotion();
				});
			} else {
				this.boldTexts?.forEach((boldText) => {
					this.intersectionObserver?.observe(boldText.element);
				});
			}
		});
	},
}
