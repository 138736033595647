/**
 * MainNavigation
 * - implements mobile navigation toggle
 * - changes visual state when scrolling
 * - adds backdrop to page when mobile navigation is opened
 * - clicking on backdrop closes mobile navigation
 * - switching to desktop viewport closes mobile navigation
 */
export const MainNavigation = {
	container: document.querySelector('.mainNav') as HTMLElement,
	navToggle: document.querySelector('.mainNav .navToggle') as HTMLElement,
	backdrop: document.querySelector('.mainNavBackdrop') as HTMLElement,
	mobileNavOpen: false,
	desktopBreakpoint: '(min-width: 1280px)',
	init() {
		if(this.container) {
			this.updateScrollState();
			this.bindEvents();
		}
	},
	updateScrollState() {
		if (window.scrollY === 0) {
			this.container.classList.remove('mainNav--scrolling');
		} else {
			this.container.classList.add('mainNav--scrolling');
		}
	},
	toggleNavigation() {
		const dropdown = this.container.querySelector('.mainNav__listContainer') as HTMLElement;

		// toggle mobile nav state
		this.mobileNavOpen = !this.mobileNavOpen;

		// toggle nav button states
		this.navToggle.classList.toggle('navToggle--x');
		this.navToggle.ariaExpanded = this.mobileNavOpen ? 'true' : 'false';

		// toggle dropdown state
		dropdown.classList.toggle('mainNav__listContainer--mobileOpen');
		this.backdrop.classList.toggle('mainNavBackdrop--visible');

		// add class to container to when open
		if(this.mobileNavOpen === true) {
			this.container.classList.add('mainNav--mobileOpen');
		}
		else {
			this.container.classList.remove('mainNav--mobileOpen');
		}
	},
	bindEvents() {
		// navigation changes visual state when scrolling
		document.addEventListener('scroll', this.updateScrollState.bind(this));

		// click on backdrop toggles mobile navigation
		this.backdrop.addEventListener('click', this.toggleNavigation.bind(this));

		// click on burger button toggles mobile navigation
		this.navToggle.addEventListener('click', this.toggleNavigation.bind(this));

		// close mobile navigation when resizing to desktop
		window.addEventListener('resize', () => {
			const dropdown = this.container.querySelector('.mainNav__listContainer') as HTMLElement;

			// supress dropdown transition while resizing
			dropdown.style.transition = 'none';

			// close mobile navigation if it's open and we are resizing to desktop
			if(this.mobileNavOpen && window.matchMedia(this.desktopBreakpoint).matches) {
				this.toggleNavigation();
			}
			// remove inline styles from dropdown after resizing
			window.setTimeout(() => {
				dropdown.style.removeProperty('transition');
			}, 100);
		});
	}
};
